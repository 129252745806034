import "../../resources/less/Family/Popups/SwitchToTeacherVersionPopup.less";
import MemberCodePopup from "./MemberCodePopup";

export default class ParentOnlyFeatures {
  private readonly links: Map<HTMLElement, string>;
  private popup: MemberCodePopup;

  constructor(popup: MemberCodePopup) {
    this.links = new Map<HTMLElement, string>();
    this.popup = popup;

    $("[data-parent-only]").each((index, element) => {
      this.links.set(element, element.dataset.href);
      element.dataset.href = "";
    });

    $(Array.from(this.links.keys())).on("click", (element) => {
      this.popup.ShowMember(
        {
          id: +this.popup.currentTarget.data("parent-id"),
          name: "",
          hasCode: true,
          isParent: true,
        },
        () => {
          window.location.href = this.links.get(element.currentTarget);
        },
      );
    });
  }
}
