import "../../resources/less/Family/Popups/SwitchMemberFormPopup.less";
import { FamilyMember } from "./Types/FamilyMember";
import RestPromise from "../utils/RestPromise";
import MemberCodePopup from "./MemberCodePopup";

export default class MemberSwitch {
  private _membersLinks: JQuery<HTMLElement>;
  private readonly _members: FamilyMember[] = [];
  private readonly popup: MemberCodePopup;
  private selectedMember: FamilyMember;
  private errorField: JQuery<HTMLElement>;
  private readonly href: string;

  constructor(popup: MemberCodePopup) {
    this._membersLinks = $(".member-link");
    this.href = $(".family-options").data("href");
    this.popup = popup;

    this._membersLinks.each((index, element) => {
      this._members.push({
        id: +element.dataset.id,
        name: element.dataset.name,
        hasCode: element.dataset.hasCode === "1",
        isParent: element.dataset.isAdult === "1",
      });
    });

    this._membersLinks.on("click", (event) => {
      this.onMemberClick(+event.currentTarget.dataset.id);
    });
  }

  public static async RestChangeMember(id: number): Promise<void> {
    return RestPromise.Create(`/rest/change-member/${id}`, "POST");
  }

  private ChangeMember(familyMember: FamilyMember): void {
    MemberSwitch.RestChangeMember(familyMember.id)
      .then(() => {
        if (this.href && this.href !== "") {
          window.location.href = this.href;
          return;
        }
        window.location.reload();
      })
      .catch((error) => {
        this.errorField.text(JSON.parse(error.responseText).message);
      });
  }

  private onMemberClick(id: number): void {
    this.selectedMember = this._members.find((m) => m.id === id);
    if (this.selectedMember.hasCode) {
      this.popup.ShowMember(this.selectedMember, () => {
        this.ChangeMember(this.popup.selectedMember);
      });
    } else {
      this.ChangeMember(this.selectedMember);
    }
  }
}
