import JQuery from "jquery";

export default class SkippyToast {
  private readonly _target: JQuery<HTMLElement>;
  private readonly closeButtons: JQuery<HTMLElement>;
  private subject: HTMLElement;
  private readonly id: string;
  private readonly duration: number;
  private skippyTimeout: number;
  private isAnimating = false;

  constructor(id: string) {
    this.id = id;
    this._target = $(`#${this.id}`);
    this.duration = this._target.data("duration");
    this.closeButtons = this._target.find(
      ".skippy-toast-close-btn, .bottom-toast-button",
    );
    this.closeButtons.on("click", () => this.removeSkippyToast());

    this._target.on("transitionend", () => {
      this.isAnimating = false;
      this._target.removeClass("close-fast");
    });

    // If click outside a text-container, hide the toast
    $(".main-container").on("click.skippy-toast", (event) => {
      if (!this._isDisplayed) {
        return;
      }
      // Do not hide if the element clicked is the toast itself or the element that triggered it
      if (
        event.target.closest(".skippy-toast-container") ||
        (this.subject && $(this.subject).find(event.target).length !== 0)
      ) {
        return;
      }
      this.removeSkippyToast();
    });
  }

  private _isDisplayed = false;

  get isDisplayed(): boolean {
    return this._isDisplayed;
  }

  get target(): JQuery<HTMLElement> {
    return this._target;
  }

  /**
   * @param subject the element that triggered the toast. Providing it makes sure that the toast won't reopen on click if already open
   * @constructor
   */
  public Show(subject: HTMLElement = null): void {
    this.subject = subject;
    // Hide the toast before displaying it again
    if (this._isDisplayed) {
      this.removeSkippyToast();
      this.showAfterTransition();
      return;
    }
    clearTimeout(this.skippyTimeout);
    if (!this._isDisplayed && this.isAnimating) {
      // let it finish the animation first
      this.showAfterTransition();
      return;
    }
    this._target.trigger("focus");
    this._target.removeClass("skippy-toast-closed");
    this._isDisplayed = true;
    this.isAnimating = true;
    if (this.duration > 0) {
      this.skippyTimeout = window.setTimeout(() => {
        this.removeSkippyToast();
      }, this.duration);
    }
  }

  private showAfterTransition(): void {
    this._target.addClass("close-fast");
    this._target.on("transitionend.hide-toast", () => {
      this._target.off("transitionend.hide-toast");
      this.Show();
    });
  }

  private removeSkippyToast(): void {
    this._target.addClass("skippy-toast-closed");
    this._isDisplayed = false;
    this.isAnimating = true;
    clearTimeout(this.skippyTimeout);
  }
}
