import "../../resources/less/Family/Popups/SwitchToTeacherVersionPopup.less";
import CodeField from "../components/Forms/CodeField";
import RestPromise from "../utils/RestPromise";
import NanoPopup from "../components/Popups/NanoPopup";

export default class CSwitchToTeacher extends NanoPopup {
  private submitButton: JQuery<HTMLElement>;
  private readonly codeField: CodeField;
  private errorField: JQuery<HTMLElement>;
  private forgotPasscodeEmailSent = false;

  constructor() {
    super({
      target: ".switch-to-teacher-popup",
      willOpen: () => {
        if (this.codeField) {
          this.codeField.Reset();
          this.errorField.text("");
          this.submitButton.addClass("disabled");
        }
      },
    });

    if (this.currentTarget.find(".js-family-member-code").length > 0) {
      this.codeField = new CodeField(
        this.currentTarget.find(".js-family-member-code"),
        +this.currentTarget.data("id"),
      );
      this.errorField = this.currentTarget.find(".switch-member-error");

      this.codeField.onSuccess = () => {
        this.submitButton.removeClass("disabled");
      };

      this.codeField.onFailure = (message: string) => {
        this.errorField.text(message);
        this.codeField.Reset("failed");
      };
    }

    $("#teacher-mode-button").on("click", () => {
      this.Show();
    });

    this.submitButton = this.currentTarget.find(".confirm-button");
    this.submitButton.on("click", () => this.submit());

    this.currentTarget.find(".forgot-passcode-button").on("click", () => {
      this.currentTarget.find(".forgot-passcode-warning").removeClass("hide");

      if (this.forgotPasscodeEmailSent) {
        return;
      }
      return RestPromise.Create(`/rest/forgot-passcode`, "POST");
    });
  }

  private submit(): void {
    location.href = "/teacher";
  }
}
