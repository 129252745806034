import "../../resources/less/Globals.less";
import "../../resources/less/normalize.less";
import "../../resources/less/Troubadour/troubadourTheme.less";
import "../../resources/less/components/Buttons/CommonButtons.less";
import "../../resources/less/Family/Components/Buttons.less";
import SkippyToast from "../components/SkippyToast";
import MemberSwitch from "./MemberSwitch";
import CSwitchToTeacher from "./CSwitchToTeacher";
import ParentOnlyFeatures from "./ParentOnlyFeatures";
import MemberCodePopup from "./MemberCodePopup";

class FamilyMain {
  constructor() {
    if ($("#skippytoast-login-elsewhere").length > 0) {
      const toast = new SkippyToast("skippytoast-login-elsewhere");
      toast.Show();
    }

    const popup = new MemberCodePopup();

    new MemberSwitch(popup);
    new ParentOnlyFeatures(popup);
    new CSwitchToTeacher();
  }
}

$(window).on("load", () => {
  new FamilyMain();
});
