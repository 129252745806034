import "../../../resources/less/components/Forms/CodeField.less";
import RestPromise from "../../utils/RestPromise";

export default class CodeField {
  private readonly container: JQuery<HTMLElement>;
  private readonly codeInputs: JQuery<HTMLInputElement>;

  constructor(target: JQuery<HTMLElement>, memberId: number = null) {
    this.container = target;
    this.codeInputs = this.container.find("input");

    this._memberId = memberId;

    this.codeInputs.on("input", (event) => {
      this.handleInput(
        event.currentTarget.value,
        +event.currentTarget.dataset.index,
      );
      this._onChange();

      if (this.isValid) {
        CodeField.CheckCode(this.memberId, this.code)
          .then(() => {
            this._onSuccess();
          })
          .catch((error) => {
            this._onFailure(JSON.parse(error.responseText).message);
          });
      }
    });

    this.codeInputs.on("keydown", (event) => {
      this.handleKeyDown(event, event.code, event.currentTarget);
      this._onChange();
    });
  }

  private _memberId: number;

  public get memberId(): number {
    return this._memberId;
  }

  public set memberId(value: number) {
    this._memberId = value;
  }

  private _onChange: () => void = () => {};

  set onChange(value: () => void) {
    this._onChange = value;
  }

  private _onSuccess: () => void = () => {};

  set onSuccess(value: () => void) {
    this._onSuccess = value;
  }

  private _onFailure: (message: string) => void = () => {};

  set onFailure(value: (message: string) => void) {
    this._onFailure = value;
  }

  public get isValid(): boolean {
    for (const input of this.codeInputs) {
      if (input.value.length === 0) {
        return false;
      }
    }
    return true;
  }

  public get code(): string {
    return this.codeInputs
      .toArray()
      .map((input) => input.value)
      .join("");
  }

  private static async CheckCode(
    id: number | null,
    code: string,
  ): Promise<void> {
    await this.hashCode(code).then((outputHash) => {
      return RestPromise.Create(`/rest/check-code`, "POST", {
        id: id,
        code: outputHash,
      });
    });
  }

  private static async hashCode(str: string): Promise<number> {
    return crypto.subtle
      .digest("SHA-256", new TextEncoder().encode(str))
      .then((buf) => {
        return Array.prototype.map
          .call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2))
          .join("");
      });
  }

  public Reset(context?: string): void {
    this.codeInputs.val("");
    this.codeInputs.first().trigger("focus");
    if (context === "failed") {
      this.codeInputs.addClass("wiggle");
      const timeout = setTimeout(() => {
        this.codeInputs.removeClass("wiggle");
        clearTimeout(timeout);
      }, 500);
    }
  }

  public ToggleDisplay(toDisplay: boolean): void {
    this.container.toggleClass("hide", !toDisplay);
  }

  private handleInput(value: string, index: number): void {
    if (value.length === 0) {
      return;
    }
    const inputLength = value.length;

    if (inputLength > 1) {
      const inputValues = value.split("");

      inputValues.forEach((value, valueIndex) => {
        const nextValueIndex = index + valueIndex;
        if (nextValueIndex >= this.codeInputs.length) {
          return;
        }
        this.codeInputs[nextValueIndex].value = value;
      });
      index += inputValues.length - 2;
    }

    if (index + 1 < this.codeInputs.length) {
      this.codeInputs[index + 1].focus();
    }
  }

  private handleKeyDown(
    event: JQuery.KeyDownEvent,
    code: string,
    target: HTMLInputElement,
  ): void {
    const index = +target.dataset.index;
    const previousIndex = index - 1;
    const nextIndex = index + 1;

    const hasPreviousIndex = previousIndex >= 0;
    const hasNextIndex = nextIndex <= this.codeInputs.length - 1;
    switch (code) {
      case "ArrowLeft":
      case "ArrowUp":
        if (hasPreviousIndex) {
          this.codeInputs[previousIndex].focus();
        }
        event.preventDefault();
        break;

      case "ArrowRight":
      case "ArrowDown":
        if (hasNextIndex) {
          this.codeInputs[nextIndex].focus();
        }
        event.preventDefault();
        break;
      case "Backspace":
        if (target.value.length === 0 && hasPreviousIndex) {
          this.codeInputs[previousIndex].value = null;
          this.codeInputs[previousIndex].focus();
        }
        break;
      default:
        break;
    }
  }
}
