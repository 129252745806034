import "../../resources/less/Family/Popups/SwitchMemberFormPopup.less";
import { FamilyMember } from "./Types/FamilyMember";
import CodeField from "../components/Forms/CodeField";
import MultiPanelsNanoPopup from "../components/Popups/MultiPanelsNanoPopup";
import RestPromise from "../utils/RestPromise";

export default class MemberCodePopup extends MultiPanelsNanoPopup {
  private readonly codeField: CodeField;
  private errorField: JQuery<HTMLElement>;
  private childTitle: JQuery<HTMLElement>;
  private childTitleDefaultText: string;
  private _onMemberCodeSuccess: () => void;

  constructor(target = "#member-code-popup") {
    super({
      target: target,
      willOpen: () => {
        this.codeField.Reset();
        this.errorField.text("");
      },
    });
    this.errorField = this.currentTarget.find(".switch-member-error");
    this.codeField = new CodeField(
      this.currentTarget.find(".js-family-member-code"),
      null,
    );

    this.childTitle = this.currentTarget.find(".child-title");
    this.childTitleDefaultText = this.childTitle.text();

    this.codeField.onSuccess = () => {
      this._onMemberCodeSuccess();
      this.Hide();
    };

    this.codeField.onFailure = (message: string) => {
      this.errorField.text(message);
      this.codeField.Reset("failed");
    };

    this.currentTarget.find(".forgot-passcode-button").on("click", () => {
      if (!this.isDisplayed) {
        return;
      }
      return RestPromise.Create(`/rest/forgot-passcode`, "POST");
    });
  }

  private _selectedMember: FamilyMember;

  get selectedMember(): FamilyMember {
    return this._selectedMember;
  }

  private set name(name: string) {
    this.childTitle.text(this.childTitleDefaultText.replace("%$0name%", name));
  }

  public ShowMember(member: FamilyMember, onSuccess: () => void) {
    super.Show();
    super.goTo(0);

    this._onMemberCodeSuccess = onSuccess;

    this._selectedMember = member;

    this.currentTarget.attr(
      "data-target-parent",
      this._selectedMember.isParent ? "1" : "0",
    );
    this.name = this._selectedMember.name;
    this.codeField.Reset();
    this.codeField.memberId = this._selectedMember.id;
  }
}
